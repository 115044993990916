import React, { useState } from 'react';
import {
  Box, Button, Input, Select, Slider, SliderTrack, SliderFilledTrack, SliderThumb,
  Card, CardBody, CardHeader, Heading, FormControl, FormLabel
} from '@chakra-ui/react';

const PromptGeneratorDashboard = () => {
  const [style, setStyle] = useState('realistic');
  const [subject, setSubject] = useState('landscape');
  const [mood, setMood] = useState('serene');
  const [complexity, setComplexity] = useState(50);
  const [cameraMovement, setCameraMovement] = useState('static shot');
  const [cameraAngle, setCameraAngle] = useState('eye level');
  const [establishingScene, setEstablishingScene] = useState('');
  const [additionalDetails, setAdditionalDetails] = useState('');
  const [generatedPrompt, setGeneratedPrompt] = useState('');

 const styles = [
    'realistic', 'abstract', 'impressionist', 'surrealist', 'minimalist', 
    'pop art', 'cubist', 'art nouveau', 'pixel art', 'watercolor', 
    'gothic', 'baroque', 'futuristic', 'steampunk', 'cyberpunk',
    'renaissance', 'expressionist', 'art deco', 'manga', 'graffiti',
    'photorealistic', 'pointillism', 'digital art', 'oil painting', 'ink drawing',
    'pencil sketch', 'collage', 'mosaic', 'stained glass', 'low poly',
    'vaporwave', 'art brut', 'bauhaus', 'ukiyo-e', 'fauvism',
    'high fantasy', 'low fantasy', 'noir', 'biopunk', 'dieselpunk',
    'neo-impressionist', 'op art', 'psychedelic art', 'retro', 'sci-fi',
    'silhouette art', 'street art', 'trompe-l\'oeil', 'tessellation', 'folk art',
    'anime', 'chibi', 'concept art', 'fine art', 'graffito',
    'hyperrealism', 'magical realism', 'realism', 'romanticism', 'minimal art',
    'psychedelia', 'retro-futurism', 'zine art', 'urban art', 'video game art',
    'constructivism', 'dadaism', 'de stijl', 'figurative', 'geometric',
    'kinetic art', 'lyrical abstraction', 'mannerism', 'naive art', 'neoclassicism',
    'neo-expressionism', 'neo-pop', 'post-impressionism', 'pre-raphaelite', 'primitivism',
    'rococo', 'suprematism', 'symbolism', 'tonalism', 'transavanguardia',
    'tachisme', 'verdadism', 'visceral art', 'vorticism', 'wabi-sabi',
    'yarn bombing', 'zentangle', '8-bit', 'ascii art', 'atompunk',
    'brutalism', 'cloisonnism', 'cotton candy goth', 'dark academia', 'digital impressionism',
    'doodle art', 'ethereal', 'figurativism', 'glitch art', 'gothic revival',
    'kawaii', 'kitsch', 'light and space', 'line art', 'lowbrow',
    'macabre', 'memphis design', 'nanopunk', 'neo-tokyo', 'neue slowenische kunst',
    'new media art', 'outsider art', 'pastoral', 'photomontage', 'precisionism',
    'process art', 'rayonism'
  ];
  
  const subjects = [
    'landscape', 'portrait', 'still life', 'cityscape', 'seascape', 
    'animal', 'fantasy creature', 'vehicle', 'architecture', 'food',
    'space scene', 'underwater world', 'mythical beings', 'robots', 'historical event',
    'cat', 'dog', 'horse', 'elephant', 'lion', 'tiger', 'bear', 'wolf', 'fox', 'deer',
    'bird', 'fish', 'insect', 'reptile', 'plant', 'tree', 'flower', 'fruit', 'vegetable',
    'mountain', 'beach', 'forest', 'desert', 'jungle', 'river', 'lake', 'waterfall',
    'castle', 'skyscraper', 'bridge', 'temple', 'ruins', 'spacecraft', 'submarine',
    'dragon', 'unicorn', 'mermaid', 'centaur', 'phoenix', 'werewolf', 'vampire',
    'alien', 'cyborg', 'android', 'astronaut', 'pirate', 'knight', 'samurai',
    'car', 'motorcycle', 'bicycle', 'train', 'airplane', 'ship', 'rocket',
    'musical instrument', 'book', 'weapon', 'jewelry', 'clothing', 'furniture',
    'superhero', 'villain', 'dancer', 'athlete', 'scientist', 'explorer', 'doctor',
    'chef', 'teacher', 'child', 'elderly person', 'family', 'friends', 'lovers',
    'party', 'festival', 'carnival', 'concert', 'performance', 'battle', 'chase',
    'adventure', 'journey', 'dream', 'nightmare', 'vision', 'memory', 'reflection',
    'urban landscape', 'rural scene', 'fantasy land', 'dystopian world', 'utopian world',
    'comic strip', 'graphic novel scene', 'movie scene', 'video game scene',
    'abstract concept', 'action scene', 'aerial view', 'allegory', 'amusement park',
    'ancient civilization', 'apocalyptic scene', 'aquarium', 'archaeological site', 'archipelago',
    'aurora borealis', 'avant-garde fashion', 'backstage', 'ballet', 'bamboo forest',
    'bazaar', 'beehive', 'bioluminescent creatures', 'black hole', 'bonsai tree',
    'bookstore', 'butterfly garden', 'calligraphy', 'campfire', 'cave painting',
    'celestial event', 'chakra', 'chess game', 'circus', 'clockwork mechanism',
    'cloud formation', 'coral reef', 'cosmic event', 'crop circle', 'crystal formation',
    'cybernetic implant', 'dance of death', 'deep sea creature', 'diorama', 'DNA strand',
    'eclipse', 'ecosystem', 'electric circuit', 'enchanted object', 'end of the world',
    'escher-like impossible structure', 'fairy tale scene', 'fashion show', 'fireworks display', 'floating islands',
    'fractal pattern', 'futuristic city', 'galactic core', 'geisha', 'genetic modification',
    'ghost town', 'glacier', 'glowing mushrooms', 'graveyard', 'greek mythology scene',
    'haunted house', 'hidden doorway', 'hieroglyphics', 'hologram', 'human anatomy',
    'ice sculpture', 'imaginary friend', 'infinity pool', 'kaleidoscope', 'labyrinth',
    'lantern festival', 'lava flow', 'library of alexandria', 'lost city', 'mandala',
    'mars colony', 'masquerade ball', 'mecha', 'microorganism', 'mirage',
    'mirror maze', 'molecular structure', 'monastery', 'monument valley', 'moonscape',
    'multiverse', 'mural', 'museum exhibit', 'nanotechnology', 'nebula',
    'neural network', 'northern lights', 'oasis', 'ocean plastic', 'origami',
    'parallel universe', 'particle collision', 'pendulum wave', 'perpetual motion machine', 'philosopher\'s stone',
    'photosynthesis', 'plague doctor', 'planetary alignment', 'pocket dimension', 'post-apocalyptic ruins',
    'potion brewing', 'quantum realm', 'quasar', 'rainforest canopy', 'robotic ecosystem',
    'rube goldberg machine', 'sandstorm', 'school of fish', 'sentient planet', 'shaman',
    'shapeshifter', 'sinkhole', 'spirit animal', 'steampunk laboratory', 'stone circle',
    'subatomic particles', 'supernova', 'swarm intelligence', 'tarot card', 'terraforming',
    'time machine', 'tidal wave', 'totem pole', 'traffic jam', 'transhuman',
    'tree of life', 'underground city', 'urban legend', 'virtual reality', 'volcanic eruption',
    'vortex', 'warp drive', 'water cycle', 'wearable tech', 'web of life',
    'wormhole', 'zen garden', 'zodiac', '4th dimensional object', '7 wonders of the ancient world'
  ];
  
  const moods = [
    'serene', 'dramatic', 'mysterious', 'joyful', 'melancholic', 
    'ethereal', 'energetic', 'romantic', 'eerie', 'whimsical',
    'nostalgic', 'adventurous', 'intense', 'calm', 'hopeful',
    'chaotic', 'peaceful', 'gloomy', 'cheerful', 'tense',
    'dreamy', 'ominous', 'playful', 'solemn', 'excited',
    'lonely', 'passionate', 'tranquil', 'anxious', 'confident',
    'angry', 'loving', 'fearful', 'surprised', 'disgusted',
    'curious', 'confused', 'determined', 'satisfied', 'regretful',
    'majestic', 'thrilling', 'spooky', 'humorous', 'optimistic',
    'pessimistic', 'vibrant', 'bleak', 'mellow', 'frenzied',
    'meditative', 'rebellious', 'tragic', 'triumphant', 'wistful',
    'zen', 'empowered', 'horrific', 'blissful', 'sentimental',
    'absurd', 'aggressive', 'ambivalent', 'amused', 'apathetic',
    'awe-inspiring', 'bittersweet', 'bizarre', 'claustrophobic', 'comical',
    'contemplative', 'cynical', 'desolate', 'distraught', 'ecstatic',
    'elated', 'empathetic', 'envious', 'euphoric', 'exhilarated',
    'festive', 'flirtatious', 'forlorn', 'frustrated', 'grateful',
    'grieving', 'grumpy', 'guilty', 'harmonious', 'homesick',
    'hysterical', 'indifferent', 'infatuated', 'insecure', 'inspired',
    'intimidating', 'introspective', 'jovial', 'jubilant', 'lethargic',
    'liberating', 'manic', 'mischievous', 'miserable', 'narcissistic',
    'nonchalant', 'paranoid', 'pensive', 'perplexed', 'philosophical',
    'proud', 'reflective', 'reluctant', 'remorseful', 'resigned',
    'restless', 'reverent', 'sarcastic', 'scandalous', 'seductive',
    'shocked', 'somber', 'stoic', 'stressed', 'sublime',
    'suspenseful', 'sympathetic', 'tearful', 'uncomfortable', 'vengeful',
    'vulnerable', 'wary', 'wondrous', 'worried', 'yearning'
  ];
  

  const cameraMovements = [
    'static shot', 'pan', 'tilt', 'zoom', 'dolly', 'truck', 'pedestal', 'crane shot', 'handheld', 'steadicam',
    'tracking shot', 'aerial shot', 'time-lapse', 'slow motion', 'dutch angle', 'whip pan', 'snap zoom'
  ];

  const cameraAngles = [
    'eye level', 'low angle', 'high angle', 'bird\'s eye view', 'worm\'s eye view', 'dutch angle',
    'over-the-shoulder', 'point of view (POV)', 'two shot', 'wide shot', 'close-up', 'extreme close-up',
    'medium shot', 'long shot', 'establishing shot'
  ];

  const generatePrompt = () => {
    let additionalComplexityDetails = '';

    if (complexity > 75) {
      additionalComplexityDetails = ' Include intricate patterns and detailed textures.';
    } else if (complexity > 50) {
      additionalComplexityDetails = ' Add moderate detail and texture.';
    } else if (complexity > 25) {
      additionalComplexityDetails = ' Keep the details simple.';
    } else {
      additionalComplexityDetails = ' Minimal details are sufficient.';
    }

    const prompt = `${cameraAngle} ${cameraMovement}: ${establishingScene}. ${additionalDetails}${additionalComplexityDetails}
    
Create a ${style} image of a ${subject} with a ${mood} atmosphere.`;

    setGeneratedPrompt(prompt);
  };

  return (
    <Box maxW="2xl" mx="auto" p={4}>
      <Card>
        <CardHeader>
          <Heading as="h2" size="lg">Comprehensive Structured Prompt Generator</Heading>
        </CardHeader>
        <CardBody>
          <FormControl mb={4}>
            <FormLabel>Camera Movement</FormLabel>
            <Select onChange={(e) => setCameraMovement(e.target.value)} value={cameraMovement}>
              {cameraMovements.map((cm) => (
                <option key={cm} value={cm}>{cm}</option>
              ))}
            </Select>
          </FormControl>

          <FormControl mb={4}>
            <FormLabel>Camera Angle</FormLabel>
            <Select onChange={(e) => setCameraAngle(e.target.value)} value={cameraAngle}>
              {cameraAngles.map((ca) => (
                <option key={ca} value={ca}>{ca}</option>
              ))}
            </Select>
          </FormControl>

          <FormControl mb={4}>
            <FormLabel>Style</FormLabel>
            <Select onChange={(e) => setStyle(e.target.value)} value={style}>
              {styles.map((s) => (
                <option key={s} value={s}>{s}</option>
              ))}
            </Select>
          </FormControl>
          
          <FormControl mb={4}>
            <FormLabel>Subject</FormLabel>
            <Select onChange={(e) => setSubject(e.target.value)} value={subject}>
              {subjects.map((s) => (
                <option key={s} value={s}>{s}</option>
              ))}
            </Select>
          </FormControl>
          
          <FormControl mb={4}>
            <FormLabel>Mood</FormLabel>
            <Select onChange={(e) => setMood(e.target.value)} value={mood}>
              {moods.map((m) => (
                <option key={m} value={m}>{m}</option>
              ))}
            </Select>
          </FormControl>
          
          <FormControl mb={4}>
            <FormLabel>Complexity: {complexity}%</FormLabel>
            <Slider
              min={0}
              max={100}
              step={1}
              value={complexity}
              onChange={(value) => setComplexity(value)}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
          </FormControl>

          <FormControl mb={4}>
            <FormLabel>Establishing Scene</FormLabel>
            <Input 
              type="text" 
              placeholder="Describe the main scene" 
              value={establishingScene}
              onChange={(e) => setEstablishingScene(e.target.value)}
            />
          </FormControl>

          <FormControl mb={4}>
            <FormLabel>Additional Details</FormLabel>
            <Input 
              type="text" 
              placeholder="Add more details to the scene" 
              value={additionalDetails}
              onChange={(e) => setAdditionalDetails(e.target.value)}
            />
          </FormControl>
          
          <Button onClick={generatePrompt} width="full" colorScheme="teal">Generate Prompt</Button>
          
          {generatedPrompt && (
            <Card mt={4}>
              <CardHeader>
                <Heading as="h3" size="md">Generated Prompt</Heading>
              </CardHeader>
              <CardBody>
                <p>{generatedPrompt}</p>
              </CardBody>
            </Card>
          )}
        </CardBody>
      </Card>
    </Box>
  );
};

function App() {
  return (
    <div className="App">
      <PromptGeneratorDashboard />
    </div>
  );
}

export default App;
